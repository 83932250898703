import { Component } from "react"
import me from "../images/me.jpeg"
import "./Header.scss"

export default class Header extends Component<any, any> {
    componentDidMount() {
        window.onmousemove = (event: any) => {
            const image = document.getElementById("image-of-myself") as HTMLImageElement
            // tilt image towards mouse

        }
    }

    render() {
        return <section className={ "header" }>
            <div className={ "image-wrapper" }>
                <img alt={ "An image of myself." } className={ "blur-in" } id={ "image-of-myself" } src={ me }
                     style={ { borderRadius: "50%", aspectRatio: "1/1", height: "250px", perspective: "10px" } }/>
            </div>

            <div className={ "fade-in" }>
                <p>Hello, my name is</p>
                <p className={ "gradient-text" }>Benni Loidl</p>
                <p className={ "typed-text" }>I'm <span className={ "title-typer" }></span></p>
            </div>
        </section>
    }
}
